<template>
  <b-card
      class="logisticsfeed-edit-wrapper"
  >
    <!-- form -->
    <b-form id="logisticsfeedForm" class="edit-form mt-2">
      <!--新增费用-->
      <b-col md="12">
        <b-card header="新增费用">
          <b-row>
            <!--客户名称-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="客户名称"
                  label-for="customer_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="customer_name"
                    taggable
                    push-tags
                    v-model="logisticsfeed.customer_name"
                    :options=customer_name_options
                    class="select-size-sm"
                    placeholder="请选择客户名称"
                />
              </b-form-group>
            </b-col>
            <!--货物名称-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="货物名称"
                  label-for="product_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="product_name"
                    size="sm"
                    v-model="logisticsfeed.product_name"
                />
              </b-form-group>
            </b-col>
            <!--规格-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="规格"
                  label-for="specifications"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="specifications"
                    size="sm"
                    v-model="logisticsfeed.specifications"
                />
              </b-form-group>
            </b-col>
            <!--入库数量-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="入库数量"
                  label-for="inbound_amount"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="inbound_amount"
                    size="sm"
                    type="number"
                    v-model="logisticsfeed.inbound_amount"
                />
              </b-form-group>
            </b-col>
            <!--库存-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="库存"
                  label-for="stock_qty"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="stock_qty"
                    size="sm"
                    type="number"
                    v-model="logisticsfeed.stock_qty"
                />
              </b-form-group>
            </b-col>
            <!--出库数量-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="出库数量"
                  label-for="outbound_amount"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="outbound_amount"
                    size="sm"
                    type="number"
                    v-model="logisticsfeed.outbound_amount"
                />
              </b-form-group>
            </b-col>
            <!--结余-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="结余"
                  label-for="amount_left"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="amount_left"
                    size="sm"
                    type="number"
                    @blur="fixNumber('amount_left')"
                    v-model="logisticsfeed.amount_left"
                />
              </b-form-group>
            </b-col>
            <!--操作费收入-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="操作费收入"
                  label-for="op_amount"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="op_amount"
                    size="sm"
                    type="number"
                    @blur="fixNumber('op_amount')"
                    v-model="logisticsfeed.op_amount"
                />
              </b-form-group>
            </b-col>
            <!--仓储费-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="仓储费"
                  label-for="stock_amount"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="stock_amount"
                    size="sm"
                    type="number"
                    @blur="fixNumber('stock_amount')"
                    v-model="logisticsfeed.stock_amount"
                />
              </b-form-group>
            </b-col>
            <!--装卸费-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="装卸费"
                  label-for="load_amount"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="load_amount"
                    size="sm"
                    type="number"
                    @blur="fixNumber('load_amount')"
                    v-model="logisticsfeed.load_amount"
                />
              </b-form-group>
            </b-col>
            <!--费用日期-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="费用日期"
                  label-for="feed_date"
                  label-size="sm"
                  class="mb-1 required"
              >
                <flat-pickr
                    id="feed_date"
                    v-model="logisticsfeed.feed_date"
                    class="form-control"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--备注-->
          <b-row>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-textarea
                    id="remark"
                    size="sm"
                    v-model="logisticsfeed.remark"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--承运单位信息-->
      <b-col md="12">
        <b-card header="承运单位信息">
          <b-row>
            <!--承运人-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="承运人"
                  label-for="carriager"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="carriager"
                    taggable
                    push-tags
                    v-model="logisticsfeed.carriager"
                    :options=carriager_options
                    class="select-size-sm"
                    placeholder="请选择承运人"
                    @input="getCarNo"
                />
              </b-form-group>
            </b-col>
            <!--车辆类型-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车辆类型"
                  label-for="car_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="car_type"
                    :options="getCodeOptions('warehouse_feed_cartype')"
                    v-model="logisticsfeed.car_type"
                    class="select-size-sm"
                    placeholder="请选择车辆类型"
                    @input="getCarNo"
                />
              </b-form-group>
            </b-col>
            <!--车牌号-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车牌号"
                  label-for="car_no"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="car_no"
                    :options="car_no_options"
                    v-model="car_no"
                    class="select-size-sm"
                    placeholder="请选择车牌号"
                    @input="getCarInfo($event)"
                    :disabled="car_no_disable"
                />
              </b-form-group>
            </b-col>
            <!--车型-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车型"
                  label-for="car_cycle"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_cycle"
                    size="sm"
                    readonly
                    v-model="logisticsfeed.car_cycle"
                />
              </b-form-group>
            </b-col>
            <!--司机姓名-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机姓名"
                  label-for="car_driver"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_driver"
                    size="sm"
                    readonly=""
                    v-model="logisticsfeed.car_driver"
                />
              </b-form-group>
            </b-col>
            <!--司机电话-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机电话"
                  label-for="car_tel"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_tel"
                    size="sm"
                    readonly=""
                    v-model="logisticsfeed.car_tel"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--保存按钮-->
      <b-col cols="12" class="mt-50">
        <b-button variant="primary" class="mr-1" @click="save">
          保存
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">
          取消
        </b-button>
      </b-col>

    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import logisticsfeedStore from './logisticsfeedStore'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, addNumber,
  isEmpty, second
} from '@core/utils/filter'
import axios from '@axios'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    BFormInvalidFeedback,
  },
  data() {
    return {
      id: ref(0),
      logisticsfeed: ref({}),
      customer_name_options: [],
      carriager_options: [],
      car_no_options:[],
      car_no:{},
      car_no_disable:true
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('logisticsfeed')) store.registerModule('logisticsfeed', logisticsfeedStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('logisticsfeed')) store.unregisterModule('logisticsfeed')
    })

    const edit = function () {
      store.dispatch('logisticsfeed/edit', {id: this.id}).then(res => {
        this.logisticsfeed = res.data.data
        if(!this.logisticsfeed.new){
          this.logisticsfeed.customer_name = {label:this.logisticsfeed.customer_name}
          this.logisticsfeed.carriager = {label:this.logisticsfeed.carriager}
          this.logisticsfeed.car_type = getCode('warehouse_feed_cartype', this.logisticsfeed.car_type)
          this.logisticsfeed.feed_date = toDate(this.logisticsfeed.feed_date)
          this.car_no = {label:this.logisticsfeed.car_no}
          this.car_no_disable = false
          this.initCarNo(this.logisticsfeed.carriager.label,this.logisticsfeed.car_type.value)
        }
      })
    }

    const view = function () {
      store.dispatch('logisticsfeed/view', {id: this.id}).then(res => {
        this.logisticsfeed = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      //校验
      let flag = true
      if(isEmpty(this.logisticsfeed.customer_name)){
        toast.error('请选择 客户名称')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.product_name)){
        toast.error('请填写 货物名称')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.specifications)){
        toast.error('请填写 规格')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.inbound_amount)){
        toast.error('请填写 入库数量')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.stock_qty)){
        toast.error('请填写 库存')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.outbound_amount)){
        toast.error('请填写 出库数量')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.amount_left)){
        toast.error('请填写 结余')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.op_amount)){
        toast.error('请填写 操作费收入')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.feed_date)){
        toast.error('请选择 费用日期')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.carriager)){
        toast.error('请选择 承运人')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.car_type)){
        toast.error('请选择 车辆类型')
        flag = false
      }
      if(isEmpty(this.car_no)){
        toast.error('请填写 车牌号')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.car_cycle)){
        toast.error('车型 不能为空')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.car_driver)){
        toast.error('司机姓名 不能为空')
        flag = false
      }
      if(isEmpty(this.logisticsfeed.car_tel)){
        toast.error('司机电话 不能为空')
        flag = false
      }
      if (!/^[0-9]\d*$/.test(this.logisticsfeed.inbound_amount)) {
        toast.error('入库数量 必须是非负整数')
        flag = false
      }
      if (!/^[0-9]\d*$/.test(this.logisticsfeed.stock_qty)) {
        toast.error('库存 必须是非负整数')
        flag = false
      }
      if (!/^[0-9]\d*$/.test(this.logisticsfeed.outbound_amount)) {
        toast.error('出库数量 必须是非负整数')
        flag = false
      }
      if(this.logisticsfeed.amount_left < 0){
        toast.error('结余 不能为负')
        flag = false
      }
      if(this.logisticsfeed.op_amount < 0){
        toast.error('操作费收入 不能为负')
        flag = false
      }
      if(this.logisticsfeed.stock_amount < 0){
        toast.error('仓储费 不能为负')
        flag = false
      }
      if(this.logisticsfeed.load_amount < 0){
        toast.error('装卸费 不能为负')
        flag = false
      }

      if(!flag) return

      this.$swal({
        title: '确定数据录入无误吗？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '已确认',
        cancelButtonText: '再看看',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          //转换
          this.logisticsfeed.customer_name = this.logisticsfeed.customer_name.label
          this.logisticsfeed.carriager = this.logisticsfeed.carriager.label
          this.logisticsfeed.car_type = this.logisticsfeed.car_type.value
          this.logisticsfeed.feed_date = second(this.logisticsfeed.feed_date)
          this.logisticsfeed.car_no = this.car_no.label

          store.dispatch('logisticsfeed/save', this.logisticsfeed).then(res => {
            if(res.data.code == 0){
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-logisticsfeed-list'});
            }else {
              toast.error(res.data.data)
            }
          })
        }
      })

    }

    const initOptions = function () {
      let that = this
      axios.post('/api/logisticsuser/list').then(function (res) {
        let list = res.data.data.list
        list.forEach(item => {
          if (item.user_type === 1) {
            that.customer_name_options.push({label: item.user_name})
          } else if (item.user_type === 2){
            that.carriager_options.push({label: item.user_name})
          }
        })
      })
    }

    const getCarInfo = function (param) {
      if (isEmpty(param)) {
        this.logisticsorder.car_cycle = ''
        this.logisticsorder.car_driver = ''
        this.logisticsorder.car_tel = ''
        this.$forceUpdate()
      }
      let that = this
      axios.post('/api/logisticscarriager/getCarInfo', {car_no: param.label}).then(function (res) {
        if (res.data.code == 1) {
          that.logisticsfeed.car_cycle = ''
          that.logisticsfeed.car_driver = ''
          that.logisticsfeed.car_tel = ''
        } else {
          that.logisticsfeed.car_cycle = res.data.data.car_cycle
          that.logisticsfeed.car_driver = res.data.data.car_driver
          that.logisticsfeed.car_tel = res.data.data.car_tel
        }
        that.$forceUpdate()
      })
    }

    const fixNumber = function (type) {
      if(!isEmpty(this.logisticsfeed[type])){
        this.logisticsfeed[type] = Number(this.logisticsfeed[type]).toFixed(2)
      }else {
        this.logisticsfeed[type] = ''
      }
      this.$forceUpdate()
    }

    //获取车牌号
    const getCarNo = function (){
      //清空
      this.car_no = {}
      this.logisticsfeed.car_cycle = ''
      this.logisticsfeed.car_driver = ''
      this.logisticsfeed.car_tel = ''
      this.car_no_options = []
      if(isEmpty(this.logisticsfeed.carriager) || isEmpty(this.logisticsfeed.car_type)){
        this.car_no_disable = true
      }else {
        this.car_no_disable = false
        let that = this
        axios.post('/api/logisticscarriager/getCarNo',
            {carriager: this.logisticsfeed.carriager.label,car_type:this.logisticsfeed.car_type.value}).then(function (res) {
          if (res.data.code == 0) {
            let resList = res.data.data
            resList.forEach(item => {
              that.car_no_options.push({label: item.car_no})
              that.$forceUpdate()
            })
          }
        })
      }
    }

    //初始化车牌号
    const initCarNo = function (carriager,car_type){
      let that = this
      axios.post('/api/logisticscarriager/getCarNo',
          {
            carriager: carriager,
            car_type: car_type
          }).then(function (res) {
        if (res.data.code == 0) {
          let resList = res.data.data
          resList.forEach(item => {
            that.car_no_options.push({label: item.car_no})
            that.$forceUpdate()
          })
        }
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      initOptions,
      getCarInfo,
      fixNumber,
      getCarNo,
      initCarNo,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
    this.initOptions()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
